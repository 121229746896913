<template>
    <div>
      <!-- Transactions par mois (chiffre d'affaire) -->
      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg shadow">
            <v-tabs
              v-model="tab"
              active-class="gifty white--text"
              hide-slider
              centered
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-promo_codes-1"> Mois</v-tab>
              <v-tab href="#tab-promo_codes-2"> Semaines</v-tab>
              <v-tab href="#tab-promo_codes-3"> Jours</v-tab>
  
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-promo_codes-1">
                <v-card flat>
                  <v-card-text>
                    <v-card-title>
                        Codes Promo dans les transactions par mois (CA)
                    </v-card-title>
                    <v-card-text>
                      <v-skeleton-loader
                        type="article"
                        v-if="lastFiveMonthsLoading"
                      />
                      
                      <TwoBars
                        v-else
                        :labels="lastFiveMonths.graph.labels"
                        :data="[lastFiveMonths.graph.amounts, lastFiveMonths.graphTransactionByQuantity.counts]"
                        :titles='["Volume Codes Promo dans les Transactions par mois (CA)", "Nombre de Transactions avec Code Promo par mois"]'
                        is_money="true"
                      />
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-tab-item>
  
               <v-tab-item value="tab-promo_codes-2">
                <v-card flat>
                  <v-card-text>
                    <v-card-title>
                        Codes Promo dans les transactions par semaines (CA)
                    </v-card-title>
                    <v-card-text>
                      <v-skeleton-loader
                        type="article"
                        v-if="lastFiveMonthsByWeekLoading"
                      />
  
                      <TwoBars
                        v-else
                        :labels="lastFiveMonthsByWeek.graphByWeek.labels"
                        :data="[lastFiveMonthsByWeek.graphByWeek.values, lastFiveMonthsByWeek.graphByWeekQuantity.counts]"
                        :titles='["Volume Codes Promo dans les Transactions par semaines (CA)", "Nombre Transactions avec Code Promo par semaines"]'
                        is_money="true"
                      />
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-tab-item>
  
              <v-tab-item value="tab-promo_codes-3">
                <v-card flat>
                  <v-card-text>
                    <v-card-title>
                        Codes Promo dans les transactions par Jours (CA)
                    </v-card-title>
                    <v-card-text>
                      <v-skeleton-loader
                        type="article"
                        v-if="lastMonthsByDayLoading"
                      />
                      
                      <TwoBars
                        v-else
                        :labels="lastMonthsByDay.graphByDays.labels"
                        :data="[lastMonthsByDay.graphByDays.values, lastMonthsByDay.graphByDaysQuantity.counts]"
                        :titles='["Volume Codes Promo dans les Transactions par jours (CA)", "Nombre de Transactions avec Code Promo par jour"]'
                        is_money="true"
                      />
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-tab-item> 
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
  
    </div>
  </template>
  
  
  <script>
  import { HTTP } from "@/http-common";
  
  import TwoBars from "@/components/charts/TwoBars.vue";
  
  export default {
    components: {TwoBars },
    data() {
      return {
        tab: null,
        
        lastFiveMonthsLoading: true,
        lastFiveMonths: {},
  
        lastFiveMonthsByWeekLoading: true,
        lastFiveMonthsByWeek: {},
  
        lastMonthsByDayLoading: true,
        lastMonthsByDay: {}
  
      };
    },
  
    methods: {
      getLastFiveMonths() {
        this.lastFiveMonthsLoading = true;
        HTTP.get("dashboard/lastMonthsTransactionPromoCodesByMonth")
          .then((res) => {
            this.lastFiveMonths = res.data;
            this.lastFiveMonthsLoading = false;
          })
          .catch((e) => {
            this.lastFiveMonthsLoading = false;
            console.log(e);
          });
      },
  
      getLastFiveMonthsByWeek() {
        this.lastFiveMonthsByWeekLoading = true;
        HTTP.get("dashboard/lastFiveMonthsTransactionPromoCodesByWeek")
          .then((res) => {
            this.lastFiveMonthsByWeek = res.data;
            this.lastFiveMonthsByWeekLoading = false;
          })
          .catch((e) => {
            this.lastFiveMonthsByWeekLoading = false;
            console.log(e);
          });
      },
  
      getLastMonthsByDay() {
        this.lastMonthsByDayLoading = true;
        HTTP.get("dashboard/lastMonthsTransactionPromoCodesByDay")
          .then((res) => {
            this.lastMonthsByDay = res.data;
            this.lastMonthsByDayLoading = false;
          })
          .catch((e) => {
            this.lastMonthsByDayLoading = false;
            console.log(e);
          });
      },
    },
    mounted() {
      this.getLastFiveMonths();
      this.getLastFiveMonthsByWeek();
      this.getLastMonthsByDay();
    },
  };
  </script>
  
  <style scoped>
  </style>