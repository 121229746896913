<template>
    <div>
        <v-row v-if="loading">
        <v-col cols="12" lg="6" v-for="item in 2" :key="item">
          <v-card class="rounded-lg shadow">
            <v-card-text>
              <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
        </v-row>

        <v-row v-else>
            <v-col cols="12" lg="12" >
            <v-card class="rounded-lg shadow">
              <v-card-title class="text--secondary">
                Nombre d'utilsateurs par version
              </v-card-title>
              <v-card-text>
                <v-skeleton-loader type="article" v-if="!graphs.graphCount"/>
                <DoughnutChart v-else
                    :labels="graphs.graphCount.labels"
                    :data="graphs.graphCount.values"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" lg="6" >
            <v-card class="rounded-lg shadow">
              <v-card-title class="text--secondary">
                Pourcentage d'utilsateurs par version
              </v-card-title>
              <v-card-text>
                <v-skeleton-loader type="article" v-if="!graphs.graphPercent"/>
                <DoughnutChart v-else
                    :labels="graphs.graphPercent.labels"
                    :data="graphs.graphPercent.values"
                />
              </v-card-text>
            </v-card>
          </v-col> -->
        </v-row>
    </div>
</template>

<script>
import { HTTP } from '@/http-common';
import DoughnutChart from "../../components/charts/DoughnutChart.vue";
export default {
    components: {DoughnutChart},
    data() {
        return {
            graphs: [],
            loading: true
        }
    },
    methods: {
        fetchData() {
            this.loading = true;
            HTTP.get("/v1/users/statistiques/version").then((res) => {
                this.loading = false;
                this.graphs = res.data.data;
                console.log(res.data.data);

            }).catch((e) => {
                this.loading = false;
                console.log(e);
            });
        }
    },

    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped></style>