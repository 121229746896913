<template>
  <div>
    <v-container fluid>

      <v-row v-if="isLoading">
        <v-col cols="12" lg="4" v-for="item in 6" :key="item">
          <v-card class="rounded-lg shadow">
            <v-card-text>
              <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="8" v-for="(item, i) in 1" :key="i">
          <v-card class="rounded-lg shadow">
            <v-card-text>
              <v-skeleton-loader type="image,article"></v-skeleton-loader>
              <v-skeleton-loader
                  class="mt-2"
                  type="paragraph"
              ></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="4">
          <v-card class="rounded-lg shadow">
            <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
            <v-skeleton-loader
                v-for="(item, i) in 7"
                class="my-1"
                :key="i"
                type="list-item-avatar"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>

      <div v-else>
        <v-row>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="primary" size="55">
                      <v-icon color="white">mdi-currency-usd</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                     {{ CurrencyFormatting($store.state.dashboardData.balance) }} DZD
                    </div>
                    Solde Actuel
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="error" size="55">
                      <v-icon color="white">mdi-currency-usd</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ CurrencyFormatting(dashboardData.total_consumed) }} DZD
                    </div>
                    Total consomation
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="success" size="55">
                      <v-icon color="white">mdi-database</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ $store.state.dashboardData.total_databases }}
                    </div>
                    Base de données
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="info" size="55">
                      <v-icon color="white">mdi-sync</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 font-weight-black">
                      <span class=" ">{{ $store.state.dashboardData.total_transactions }}</span> 
                    </div>
                    Transactions
                   
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="primary" size="55">
                      <v-icon color="white">mdi-currency-usd</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ CurrencyFormatting($store.state.dashboardData.total_transactions_amount) }} DZD
                    </div>
                    Volume des transactions 
                    
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Frais -->
          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="info" size="55">
                      <v-icon color="white">mdi-sync</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 font-weight-black">
                      <span class=" ">{{ $store.state.dashboardData.total_transaction_fees }}</span> 
                    </div>
                    Transactions avec Frais
                   
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="gifty" size="55">
                      <v-icon color="white">mdi-cash-multiple</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">

                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ CurrencyFormatting($store.state.dashboardData.total_transaction_fees_amount) }} DZD
                    </div>
                    Volume des Frais 
                    
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- End frais -->


          <!-- Promotions -->
          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="info" size="55">
                      <v-icon color="white">mdi-wallet-giftcard</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 font-weight-black">
                      <span class=" ">{{ $store.state.dashboardData.total_transaction_promotions }}</span> 
                    </div>
                    Transactions avec Promotion
                   
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="gifty" size="55">
                      <v-icon color="white">mdi-wallet-giftcard</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">

                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ CurrencyFormatting($store.state.dashboardData.total_transaction_promotions_amount) }} DZD
                    </div>
                    Volume des Promotions 
                    
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- End Promotions -->


          <!-- Promo Codes -->
          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="gifty" size="55">
                      <v-icon color="white">mdi-barcode-scan</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">

                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ CurrencyFormatting($store.state.dashboardData.total_transaction_promo_codes_amount) }} DZD
                    </div>
                    <div class="font-weight-black subtitle-1 pb-1 grey--text text--darken-3">
                    {{  $store.state.dashboardData.total_transaction_promo_codes }} Activation(s) Code Promo
                  </div>
                    
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- End Promotions Codes -->

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="success" size="55">
                      <v-icon color="white">mdi-currency-usd</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">

                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ CurrencyFormatting( parseFloat($store.state.dashboardData.total_transactions_amount)  
                          + parseFloat($store.state.dashboardData.total_transaction_fees_amount)
                          - parseFloat($store.state.dashboardData.total_transaction_promotions_amount)
                          - parseFloat($store.state.dashboardData.total_transaction_promo_codes_amount)
                        ) }} DZD
                    </div>
                    Volume Total des Transactions 
                    
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Utilisateur  -->
          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="gifty" size="55">
                      <v-icon color="white">mdi-account-check</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ $store.state.dashboardData.total_users }}
                    </div>
                    Nombre d'utilisateurs
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Utilsateur -->

          <!-- Sms envoyes-->
          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="blue" size="55">
                      <v-icon color="white">mdi-message-processing</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ $store.state.dashboardData.total_sms }}
                    </div>
                    Nombre SMS envoyés
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Sms envoyes-->
          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-avatar color="blue" size="55">
                      <v-icon color="white">mdi-message-processing</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ $store.state.dashboardData.total_sms_resent }}
                    </div>
                    SMS renvoyés
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Transactions par mois (chiffre d'affaire) -->
        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg shadow">
              <v-tabs v-model="tab"
                      active-class="gifty white--text"
                      hide-slider
                      centered>
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#tab-1"> Mois</v-tab>
                <v-tab href="#tab-2"> Semaines</v-tab>
                <v-tab href="#tab-3"> Jours</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title>
                        Volume des transactions par mois (CA)
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader type="article" v-if="lastFiveMonthsTransactionsLoading"/>
                        <Bar v-else
                            :labels="lastFiveMonthsTransactions.graph.months"
                            :data="lastFiveMonthsTransactions.graph.amounts"
                            title="Transactions par mois (CA)"
                            is_money="true"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="tab-2">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title>
                        Volume des transactions par semaines (CA)
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader type="article" v-if="lastFiveMonthsTransactionsByWeekLoading"/>
                        <Bar v-else
                            :labels="lastFiveMonthsTransactionsByWeek.graphByWeek.labels"
                            :data="lastFiveMonthsTransactionsByWeek.graphByWeek.values"
                            title="Transactions par semaines (CA)"
                            is_money="true"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="tab-3">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title>
                        Volume des transactions par Jours (CA)
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader type="article" v-if="lastMonthsTransactionsByDayLoading"/>
                        <Bar v-else
                            :labels="lastMonthsTransactionsByDay.graphByDays.labels"
                            :data="lastMonthsTransactionsByDay.graphByDays.values"
                            title="Transactions par jours (CA)"
                            is_money="true"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>

        

        <!-- Transactions par mois (Nombre) -->
        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg shadow">
              <v-tabs
                  v-model="tab_2"
                  active-class="gifty white--text"
                  hide-slider
                  centered
              >
                <v-tab key="transaction_one">
                  Mois
                </v-tab>
                <v-tab key="transaction_two">
                  Semaines
                </v-tab>
                <v-tab key="transaction_three">
                  Jours
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab_2">
                <v-tab-item key="transaction_one">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title>
                        Nombre de Transactions par mois
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader type="article" v-if="lastFiveMonthsTransactionsLoading"/>
                        <Bar v-else
                            :labels="lastFiveMonthsTransactions.graphTransactionByQuantity.months"
                            :data="lastFiveMonthsTransactions.graphTransactionByQuantity.counts"
                            title="Transactions par mois (Quantité)"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item key="transaction_two">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title>
                        Nombre Transactions par semaines
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader type="article" v-if="lastFiveMonthsTransactionsByWeekLoading"/>
                        <Bar v-else
                            :labels="lastFiveMonthsTransactionsByWeek.graphByWeekQuantity.labels"
                            :data="lastFiveMonthsTransactionsByWeek.graphByWeekQuantity.counts"
                            title="Transactions par semaines (Nombre)"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item key="transaction_three">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title>
                        Nombre Transactions par Jours
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader type="article" v-if="lastMonthsTransactionsByDayLoading"/>
                        <Bar v-else :labels="lastMonthsTransactionsByDay.graphByDaysQuantity.labels"
                             :data="lastMonthsTransactionsByDay.graphByDaysQuantity.counts"
                             title="Transactions par jours (Nombre)"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>

        <!-- Free Transactions -->
        <FeesTabs/>
        
        <PromotionTabs />

        <PromoCodeTabs />

        <v-row v-if="can('admin')">
          
          <v-col cols="12" lg="6" >
            <v-card class="rounded-lg shadow">
              <v-card-title class="text--secondary">
                Type de point de ventes
              </v-card-title>
              <v-card-text>
                <v-skeleton-loader type="article" v-if="salepoints_types_loading"/>
                <DoughnutChart v-else
                    :labels="typeSailpoints.types"
                    :data="typeSailpoints.numbers"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="6">
            <v-card class="rounded-lg shadow">
              <v-card-title class="text--secondary">
                Statistiques d'utilisation
              </v-card-title>
              <v-card-text>
                <DoughnutChart
                    :labels="ac"
                    :data="[
                    activeUsers.accounts_active,
                    activeUsers.accounts_inactive,
                  ]"
                    cutout="0"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card class="shadow rounded-lg">
              <v-card-title class="text--secondary">
                Inscriptions dans les derniers 12 mois
              </v-card-title>
              <v-card-text>
                <Bar v-if="dashboardData && dashboardData.graphUsersByMonth"
                    :labels="dashboardData.graphUsersByMonth.labels"
                    :data="dashboardData.graphUsersByMonth.values"
                    title="Nombre d'inscription"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <UsersVersionVue />
      </div>
    </v-container>
  </div>
</template>

<script>
import Bar from "@/components/charts/Bar.vue";
import DoughnutChart from "../../components/charts/DoughnutChart.vue";
//import TransactionItem from "./components/TransactionItem.vue";
import { HTTP } from '@/http-common';
import FeesTabs from './components/transactionFee/FeesTabs.vue';
import PromotionTabs from "./components/transationPromotion/PromotionTabs.vue";
import PromoCodeTabs from './components/transationPromotion/PromoCodeTabs.vue';
import UsersVersionVue from "./UsersVersion.vue";

export default {
  components: {
    //TransactionItem,
    DoughnutChart,
    Bar,
    FeesTabs,
    PromotionTabs,
    PromoCodeTabs,
    UsersVersionVue
},

  data() {
    return {
      tab: null,
      tab_2: null,
      loading: false,

      // salepoints_types
      salepoints_types: [],
      salepoints_types_loading: false,

      // Active users
      activeUsers: {},
      activeUsersLoading: true,
      ac: ["Actif", "InActif"],

      // lastFiveMonthsTransactions
      lastFiveMonthsTransactions: {},
      lastFiveMonthsTransactionsLoading: true,

      //lastFiveMonthsTransactionsByWeekLoading
      lastFiveMonthsTransactionsByWeekLoading: true,
      lastFiveMonthsTransactionsByWeek: {},

      //lastMonthsTransactionsByDay
      lastMonthsTransactionsByDay: {},
      lastMonthsTransactionsByDayLoading: true

    };
  },

  methods: {
    getSalePointsTypes(){
      this.salepoints_types_loading = true;
      HTTP.get("dashboard/salePointsTypes").then((res) => {
        this.salepoints_types = res.data.salepoints_types;
        this.salepoints_types_loading = false;
        
      }).catch((e) => {
        this.salepoints_types_loading = false;

        console.log(e);
      });
    },
    getActiveUsers(){
      this.activeUsersLoading = true;
      HTTP.get("dashboard/activeUsers").then((res) => {
        this.activeUsers = res.data;
        this.activeUsersLoading = false;
        
      }).catch((e) => {
        this.activeUsersLoading = false;
        console.log(e);
      });
    },
    getLastFiveMonthsTransactions(){
      this.lastFiveMonthsTransactionsLoading = true;
      HTTP.get("dashboard/lastFiveMonthsTransactions").then((res) => {
        this.lastFiveMonthsTransactions = res.data;
        this.lastFiveMonthsTransactionsLoading = false;
        
      }).catch((e) => {
        this.lastFiveMonthsTransactionsLoading = false;
        console.log(e);
      });
    },
    getLastFiveMonthsTransactionsByWeek(){
      this.lastFiveMonthsTransactionsByWeekLoading = true;
      HTTP.get("dashboard/lastFiveMonthsTransactionsByWeek").then((res) => {
        this.lastFiveMonthsTransactionsByWeek = res.data;
        this.lastFiveMonthsTransactionsByWeekLoading = false;
        
      }).catch((e) => {
        this.lastFiveMonthsTransactionsByWeekLoading = false;
        console.log(e);
      });
    },
    getLastMonthsTransactionsByDay(){
      this.lastMonthsTransactionsByDayLoading =true;
      HTTP.get("dashboard/lastMonthsTransactionsByDay").then((res) => {
        this.lastMonthsTransactionsByDay = res.data;
        this.lastMonthsTransactionsByDayLoading = false;
        
      }).catch((e) => {
        this.lastMonthsTransactionsByDayLoading = false;
        console.log(e);
      });
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
    dashboardData() {
      return this.$store.getters.getDashboardData;
    },
    typeSailpoints() {
      return this.salepoints_types;
    },
  },
  mounted() {
    this.$store.dispatch("fetchDashboardDatafetchUsers");
    this.$store.dispatch("fetchwilayas");
    
    this.getSalePointsTypes();
    this.getActiveUsers();
    this.getLastMonthsTransactionsByDay();
    this.getLastFiveMonthsTransactions();
    this.getLastFiveMonthsTransactionsByWeek();
  },
};
</script>

