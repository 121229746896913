<template>
  <div>
    <!-- Transactions par mois (chiffre d'affaire) -->
    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg shadow">
          <v-tabs
            v-model="tab"
            active-class="gifty white--text"
            hide-slider
            centered
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-fees-1"> Mois</v-tab>
            <v-tab href="#tab-fees-2"> Semaines</v-tab>
            <v-tab href="#tab-fees-3"> Jours</v-tab>

          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-fees-1">
              <v-card flat>
                <v-card-text>
                  <v-card-title>
                    Frais des transactions par mois (CA)
                  </v-card-title>
                  <v-card-text>
                    <v-skeleton-loader
                      type="article"
                      v-if="lastFiveMonthsLoading"
                    />
                    
                    <TwoBars
                      v-else
                      :labels="lastFiveMonths.graph.labels"
                      :data="[lastFiveMonths.graph.amounts, lastFiveMonths.graphTransactionByQuantity.counts]"
                      :titles='["frais des Transactions par mois (CA)", "Nombre des frais de Transactions par mois"]'
                      is_money="true"
                    />
                  </v-card-text>
                </v-card-text>
              </v-card>
            </v-tab-item>

             <v-tab-item value="tab-fees-2">
              <v-card flat>
                <v-card-text>
                  <v-card-title>
                    Frais des transactions par semaines (CA)
                  </v-card-title>
                  <v-card-text>
                    <v-skeleton-loader
                      type="article"
                      v-if="lastFiveMonthsByWeekLoading"
                    />

                    <TwoBars
                      v-else
                      :labels="lastFiveMonthsByWeek.graphByWeek.labels"
                      :data="[lastFiveMonthsByWeek.graphByWeek.values, lastFiveMonthsByWeek.graphByWeekQuantity.counts]"
                      :titles='["frais des Transactions par semaines (CA)", "Nombre des frais de Transactions par semaines"]'
                      is_money="true"
                    />
                  </v-card-text>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-fees-3">
              <v-card flat>
                <v-card-text>
                  <v-card-title>
                    Frais des transactions par Jours (CA)
                  </v-card-title>
                  <v-card-text>
                    <v-skeleton-loader
                      type="article"
                      v-if="lastMonthsByDayLoading"
                    />
                    
                    <TwoBars
                      v-else
                      :labels="lastMonthsByDay.graphByDays.labels"
                      :data="[lastMonthsByDay.graphByDays.values, lastMonthsByDay.graphByDaysQuantity.counts]"
                      :titles='["frais des Transactions par jours (CA)", "Nombre de frais des Transactions par jour"]'
                      is_money="true"
                    />
                  </v-card-text>
                </v-card-text>
              </v-card>
            </v-tab-item> 
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>


<script>
import { HTTP } from "@/http-common";

import TwoBars from "@/components/charts/TwoBars.vue";

export default {
  components: {TwoBars },
  data() {
    return {
      tab: null,
      
      lastFiveMonthsLoading: true,
      lastFiveMonths: {},

      lastFiveMonthsByWeekLoading: true,
      lastFiveMonthsByWeek: {},

      lastMonthsByDayLoading: true,
      lastMonthsByDay: {}

    };
  },

  methods: {
    getLastFiveMonths() {
      this.lastFiveMonthsLoading = true;
      HTTP.get("dashboard/lastMonthsTransactionFeesByMonth")
        .then((res) => {
          this.lastFiveMonths = res.data;
          this.lastFiveMonthsLoading = false;
        })
        .catch((e) => {
          this.lastFiveMonthsLoading = false;
          console.log(e);
        });
    },

    getLastFiveMonthsByWeek() {
      this.lastFiveMonthsByWeekLoading = true;
      HTTP.get("dashboard/lastFiveMonthsTransactionFeesByWeek")
        .then((res) => {
          this.lastFiveMonthsByWeek = res.data;
          this.lastFiveMonthsByWeekLoading = false;
        })
        .catch((e) => {
          this.lastFiveMonthsByWeekLoading = false;
          console.log(e);
        });
    },

    getLastMonthsByDay() {
      this.lastMonthsByDayLoading = true;
      HTTP.get("dashboard/lastMonthsTransactionFeesByDay")
        .then((res) => {
          this.lastMonthsByDay = res.data;
          this.lastMonthsByDayLoading = false;
        })
        .catch((e) => {
          this.lastMonthsByDayLoading = false;
          console.log(e);
        });
    },
  },
  mounted() {
    this.getLastFiveMonths();
    this.getLastFiveMonthsByWeek();
    this.getLastMonthsByDay();
  },
};
</script>

<style scoped>
</style>